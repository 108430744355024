<!-- 资讯 -->
<template>
  <div class="message">
    <div class="content">
      <div class="top">
        <router-link to="/index" class="leftIcon"></router-link>
        <div class="rightTitle"></div>
      </div>
      <div class="messageBox" v-for="(item, index) in initData" :key="index" @click="toDetail(item.id)">
        <div class="leftImg">
          <img :src="$global.ImgUrl + item.pic" alt="" />
        </div>
        <div class="rightCon">
          <div class="text">
            {{ item.name }}
          </div>
          <div class="time">{{ item.createDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { noticeApi } from "../../request/api/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      initData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    toDetail(id) {
      this.$router.push("/messDe/" + id);
    },
    getdata() {
      noticeApi({}).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.initData = res.data.data;
          console.log(this.initData);
        }
      });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getdata();
  },
};
</script>
<style lang="less">
.message {
  width: 100%;
  height: 100vh;
  background: url("./image/bg.svg") no-repeat;
  background-size: 100%;
  position: relative;

  .content {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 1.6rem 1.55rem 0;

    .top {
      width: 16.8rem;
      height: 3.9rem;
      margin-bottom: 2.4rem;
      display: flex;
      justify-content: space-between;

      .leftIcon {
        width: 3.4rem;
        height: 3.4rem;
        background: url("./image/button_back.svg") no-repeat;
        background-size: 100%;
      }

      .rightTitle {
        width: 12.7rem;
        height: 3.8rem;
        background: url("./image/zixun.svg") no-repeat;
        background-size: 100%;
      }
    }

    .messageBox {
      width: 100%;
      height: 10.8rem;
      border-radius: 0.8rem;
      background: #ffffff;
      padding: 0.4rem;
      display: flex;
      margin-bottom: 1.2rem;

      .leftImg {
        width: 10rem;
        height: 10rem;
        border-radius: 0.6rem;
        overflow: hidden;
        margin-right: 0.6rem;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rightCon {
        width: 100%;
        height: 100%;
        padding: 0.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text {
          width: 100%;
          font-size: 1.4rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #333333;
          line-height: 2rem;
        }

        .time {
          width: 100%;
          font-size: 1.2rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
          line-height: 1.5rem;
          letter-spacing: 0rem;
        }
      }
    }
  }
}
</style>